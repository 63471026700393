<template>
  <div>
    <v-card color="primary">
      <v-card-title style="color: white" class="text-h6">
        {{ line.descricaoDia }} - {{ line.data }}
        <v-spacer></v-spacer>
        <TooltipMain text="Adicionar" top>
          <ButtonIcon
            @click.native="$emit('edit', line)"
            class="mx-1"
            :warning="true"
            icon="pencil"
          />
        </TooltipMain>
        <TooltipMain text="Excluir" top>
          <ButtonIcon
            @click.native="$emit('delete', line)"
            class="mx-1"
            :secondary="true"
            icon="delete"
          />
        </TooltipMain>
      </v-card-title>
      <v-card-text class="white text--primary">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Inicia</th>
                <th class="text-center">Encerra</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="horario in line.horarios" :key="horario.horaInicio">
                <td class="text-center">
                  <ChipMain :tag="{ descricao: horario.horaInicio }" />
                </td>
                <td class="text-center">
                  <ChipMain :tag="{ descricao: horario.horaFim }" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-center mt-2">
          <ChipMain
            :tag="{ descricao: `${line.horarios.length} Atendimentos` }"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ChipMain from '../../atoms/items/ChipMain.vue'
import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
import TooltipMain from '../../atoms/TooltipMain.vue'

export default {
  props: ['line'],
  components: {
    ChipMain,
    ButtonIcon,
    TooltipMain
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
</style>
