<template>
  <div>
    <v-timeline dense>
      <TimelineMain
        @edit="$emit('edit', true)"
        @delete="$emit('delete', line)"
        v-for="line in lineList"
        :key="line.time"
        :line="line"
      />
    </v-timeline>
  </div>
</template>

<script>
import TimelineMain from "../../molecules/timelines/TimelineMain";
import AlertMain from "../../atoms/AlertMain.vue";

export default {
  components: {
    TimelineMain,
    AlertMain,
  },
  props: ["lineList"],
};
</script>

<style scoped>
.v-timeline {
  padding-top: 0 !important;
}
</style>
